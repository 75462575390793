import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { EMPLOYER_TOKEN, BASE_URL } from "../../utils/Constants";
import LogoutAction from "./LogoutAction";
import EmployeeType, { GetEmployee } from "../type/employeeType";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const EmployeeAction = {
  getEmployeeList:
    (data: GetEmployee): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "employee/list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
            type: data.type,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
        );

        dispatch({
          type: EmployeeType.GET_EMPLOYEE_LIST,
          payload: {
            data: result.data.data.employees.data,
            currentPage: result.data.data.employees.current_page,
            lastPage: result.data.data.employees.last_page,
            totalData: result.data.data.employees.total,
            from: result.data.data.employees.from,
            to: result.data.data.employees.to,
            totalApprovedKYC: result.data.data.totalApprovedKYC,
            totalPendingKYC: result.data.data.totalPendingKYC,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default EmployeeAction;
