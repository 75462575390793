import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const menuList = [
  {
    name: "Dashboard",
    subMenu: false,
    active: true,
    subMenuList: [],
    url: "/dashboard",
    icon: "fa fa-home",
    extraUrl: [],
  },
  {
    name: "ASL Account Management",
    subMenu: true,
    active: true,
    icon: "fa fa-users",
    subMenuList: [
      {
        name: "ASL Account List",
        active: true,
        url: "/employee-list",
        extraUrl: ["/employee-profile/"], // Base path to check against
      },
      {
        name: "Create ASL Account",
        active: true,
        url: "/create-employee",
        extraUrl: [],
      },
    ],
  },
  {
    name: "Department",
    subMenu: false,
    active: true,
    subMenuList: [],
    url: "/department",
    icon: "fa fa-briefcase",
    extraUrl: [],
  },
];

function Sidebar() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState({
    parent: "Dashboard",
    child: "",
    icon: "fa fa-home",
  });
  const [openSubMenu, setOpenSubMenu] = useState("");

  useEffect(() => {
    const activeMenu = menuList.find((menu) => {
      if (menu.subMenu) {
        return menu.subMenuList.some((sub) => {
          const isUrlActive = location.pathname === sub.url;
          const isExtraUrlActive = sub.extraUrl.some(
            (extra) => location.pathname.startsWith(extra), // Check if the pathname starts with extraUrl item
          );
          return isUrlActive || isExtraUrlActive; // Active if either condition is true
        });
      }
      return location.pathname === menu.url;
    });

    if (activeMenu) {
      const activeSubMenu = activeMenu.subMenuList.find(
        (sub) =>
          sub.url === location.pathname ||
          sub.extraUrl.some((extra) => location.pathname.startsWith(extra)), // Check for extraUrl match
      );

      setActiveTab({
        parent: activeMenu.name,
        child: activeSubMenu ? activeSubMenu.name : "",
        icon: activeMenu.icon,
      });
      setOpenSubMenu(activeMenu.subMenu ? activeMenu.name : "");
    } else {
      setActiveTab({
        parent: "",
        child: "",
        icon: "",
      });
      setOpenSubMenu("");
    }
  }, [location.pathname]);

  const handleMenuClick = (menu: any) => {
    if (menu.subMenu) {
      setOpenSubMenu(openSubMenu === menu.name ? "" : menu.name);
    } else {
      setOpenSubMenu("");
    }
  };

  return (
    <div>
      <aside id="leftsidebar" className="sidebar">
        <div className="menu">
          <ul className="list">
            {menuList.map((item, menuIndex) => (
              <li
                key={menuIndex}
                className={activeTab.parent === item.name ? "active" : ""}
              >
                <Link
                  to={`${item.subMenu ? "#" : item.url}`}
                  className={item.subMenu ? "menu-toggle" : ""}
                  onClick={() => handleMenuClick(item)}
                >
                  <i className={item.icon}></i>
                  <span>{item.name}</span>
                </Link>

                {item.subMenu && (
                  <ul
                    className="ml-menu"
                    style={{
                      display: openSubMenu === item.name ? "block" : "none",
                    }}
                  >
                    {item.subMenuList.map((subItem, subMenuIndex) => (
                      <li key={subMenuIndex}>
                        <Link
                          to={subItem.url}
                          style={{
                            color:
                              activeTab.child === subItem.name
                                ? "red"
                                : "black",
                          }}
                        >
                          {subItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
