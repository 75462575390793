import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { useForm } from "react-hook-form";
import DepartmentAction from "../../stores/action/departmentAction";

type FormData = {
  name: string;
};

function Department() {
  const [search, set_search] = useState<string>("");
  const [limit, set_limit] = useState<number>(10);
  const [page, set_page] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  const { departmentList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.department);

  const methods = useForm<FormData>({
    defaultValues: {
      name: "",
    },
  });

  const {
    register,
    reset,
    formState: { errors },
  } = methods;

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getDepartmentList = useCallback(async () => {
    await dispatch(
      DepartmentAction.getDepartmentList({
        limit: limit,
        search: search,
        page: page,
      }),
    );
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    getDepartmentList();
  }, [getDepartmentList]);

  const onSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      await dispatch(
        DepartmentAction.createDepartment({
          name: data.name,
        }),
      );
      reset();
      setLoading(false);
      set_page(1); // Optionally reset to first page after adding
      getDepartmentList();
    } catch (error) {
      console.error("Error creating department:", error);
      setLoading(false);
      // Optionally, display an error message to the user
    }
  };

  const handleRemove = async (id: number) => {
    try {
      setLoading(true);
      // await dispatch(DepartmentAction.removeDepartment(id));
      setLoading(false);
      getDepartmentList();
    } catch (error) {
      console.error("Error removing department:", error);
      setLoading(false);
      // Optionally, display an error message to the user
    }
  };

  return (
    <WebLayout pageName={"Department"}>
      <section className="content">
        <div className="container-fluid">
          {/* ...breadcrumb and header sections... */}
          <div className="row">
            {/* Add Department Form */}
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="card">
                <div className="header">
                  <h2>Add Department</h2>
                </div>
                <div
                  className="row g-3 px-4 mb-2 d-flex
                  justify-content-between
                  align-items-end"
                >
                  <div className="col-md-3">
                    <label className="mb-2">Show Entries</label>
                    <select
                      onChange={(e) => set_limit(parseInt(e.target.value, 10))}
                      id="defaultSelect"
                      className="form-select"
                      value={limit}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-3">
                    <div className="input-group input-group-merge mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => set_search(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="row clearfix">
                    <form
                      className="needs-validation"
                      onSubmit={methods.handleSubmit(onSubmit)}
                      encType="multipart/form-data"
                    >
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Department Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Department Name"
                            {...register("name", { required: true })}
                          />
                          {errors.name && (
                            <span className="text-danger">
                              Department Name is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <button
                          type="submit"
                          className="btn btn-dark ml-auto"
                          disabled={loading}
                        >
                          {loading ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : (
                            "Save Changes"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* Department List */}
            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="card">
                <div className="header">
                  <h2>Department List</h2>
                </div>
                <div
                  className="row g-3 px-4 mb-2 d-flex
                  justify-content-between
                  align-items-end"
                >
                  <div className="col-md-3">
                    <label className="mb-2">Show Entries</label>
                    <select
                      onChange={(e) => set_limit(parseInt(e.target.value, 10))}
                      id="defaultSelect"
                      className="form-select"
                      value={limit}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-3">
                    <div className="input-group input-group-merge mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => set_search(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center">Name</th>
                          <th className="center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {departmentList?.length > 0 ? (
                          departmentList.map((dataList: any, index: number) => (
                            <tr key={dataList.id}>
                              <td>{(page - 1) * limit + index + 1}</td>
                              <td>{dataList.name}</td>
                              <td>
                                <button
                                  className="btn btn-sm btn-danger"
                                  style={{ borderRadius: "0" }}
                                  onClick={() => handleRemove(dataList.id)}
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <i className="fa fa-spinner fa-spin"></i>
                                  ) : (
                                    "Remove"
                                  )}
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={3} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {departmentList?.length > 0 && (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto me-auto">
                        <div className="dt-info">
                          Showing {from} to {to} of {totalData} entries
                        </div>
                      </div>
                      <div className="col-md-auto ms-auto">
                        <div className="dt-paging paging_full_numbers">
                          <ul className="pagination">
                            {/* First Page */}
                            <li
                              className={`dt-paging-button page-item ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() => page !== 1 && set_page(1)}
                            >
                              <a
                                className="page-link first"
                                aria-controls="tableGroup"
                                aria-disabled={page === 1}
                                aria-label="First"
                                data-dt-idx="first"
                              >
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>

                            {/* Previous Page */}
                            <li
                              className={`dt-paging-button page-item prev ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() => page !== 1 && set_page(page - 1)}
                            >
                              <a
                                className="page-link previous"
                                aria-controls="tableGroup"
                                aria-disabled={page === 1}
                                aria-label="Previous"
                                data-dt-idx="previous"
                              >
                                <span aria-hidden="true">&lt;</span>
                              </a>
                            </li>

                            {/* Page Numbers */}
                            {pagination.map((p: number) => (
                              <li
                                key={p}
                                className={`dt-paging-button page-item ${
                                  page === p ? "active" : ""
                                }`}
                                onClick={() => set_page(p)}
                              >
                                <a
                                  className="page-link"
                                  aria-controls="tableGroup"
                                  aria-current="page"
                                  data-dt-idx="0"
                                >
                                  {p}
                                </a>
                              </li>
                            ))}

                            {/* Next Page */}
                            <li
                              className={`dt-paging-button page-item next ${
                                page === lastPage ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page !== lastPage && set_page(page + 1)
                              }
                            >
                              <a
                                className="page-link next"
                                aria-controls="tableGroup"
                                aria-label="Next"
                                data-dt-idx="next"
                              >
                                <span aria-hidden="true">&gt;</span>
                              </a>
                            </li>

                            {/* Last Page */}
                            <li
                              className={`dt-paging-button page-item ${
                                page === lastPage ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page !== lastPage && set_page(lastPage)
                              }
                            >
                              <a
                                className="page-link last"
                                aria-controls="tableGroup"
                                aria-disabled={page === lastPage}
                                aria-label="Last"
                                data-dt-idx="last"
                              >
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default Department;
