import React, { useEffect, useCallback, useState } from "react";
import WebLayout from "../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import WebAction from "../../stores/action/webAction";
import { useForm, FormProvider } from "react-hook-form";
import { Link } from "react-router-dom";

type FormData = {
  image: FileList;
  name: string;
  fatherName: string;
  dateOfBirth: string;
  phone: string;
  state: number;
  district: number;
  pinCode: string;
  correspondenceAddress: string;
  permanentAddress: string;

  email: string;
  password: string;

  employeeID: string;
  department: string;
  designation: number;
  dateOfJoining: string;
  joiningSalary: string;

  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  ifscCode: string;
  branch: string;
};

function CreateEmployee() {
  const dispatch = useDispatch<any>();

  const { stateList, districtList } = useSelector(
    (state: RootState) => state.web,
  );

  const methods = useForm<FormData>({
    defaultValues: {
      // Define default values if necessary
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = methods;

  const [btnLoading, setBtnLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const getStates = useCallback(async () => {
    await dispatch(WebAction.getState());
  }, [dispatch]);

  useEffect(() => {
    getStates();
  }, [getStates]);

  const getDistrict = useCallback(
    async (state_id: number) => {
      try {
        await dispatch(WebAction.getDistrict({ state_id }));
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch],
  );

  const handleStateChange = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const stateId = parseInt(event.target.value, 10);
    setValue("state", stateId);
    await getDistrict(stateId);
  };

  const getStepFields = (step: number): Array<keyof FormData> => {
    switch (step) {
      case 1:
        return [
          "image",
          "name",
          "fatherName",
          "dateOfBirth",
          "phone",
          "state",
          "district",
          "pinCode",
          "correspondenceAddress",
          "permanentAddress",
        ];
      case 2:
        return ["email", "password"];
      case 3:
        return [
          "employeeID",
          "department",
          "designation",
          "dateOfJoining",
          "joiningSalary",
        ];
      case 4:
        return [
          "accountHolderName",
          "accountNumber",
          "bankName",
          "ifscCode",
          "branch",
        ];
      default:
        return [];
    }
  };

  const onSubmit = async (data: FormData) => {
    setBtnLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", data.image[0]); // Assuming single file upload
      // Append other form fields
      formData.append("name", data.name);
      // ... append other fields similarly

      // Reset the form or navigate as needed
      reset();
    } catch (error) {
      console.error(error);
      // Handle error (e.g., display a notification)
    } finally {
      setBtnLoading(false);
    }
  };

  const nextStep = async () => {
    const stepFields: Array<keyof FormData> = getStepFields(currentStep);
    const isValid = await trigger(stepFields);
    if (isValid) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>Image</label>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*"
                    {...register("image", {
                      required: "Image is required",
                      validate: {
                        acceptedFormats: (files) => {
                          const file = files?.[0];
                          const acceptedFormats = [
                            "image/jpeg",
                            "image/png",
                            "image/gif",
                          ];
                          if (file && !acceptedFormats.includes(file.type)) {
                            return "Only JPEG, PNG, or GIF files are allowed";
                          }
                          return true;
                        },
                        fileSize: (files) => {
                          const file = files?.[0];
                          if (file && file.size > 2 * 1024 * 1024) {
                            return "Image size should not exceed 2MB";
                          }
                          return true;
                        },
                      },
                    })}
                  />
                  {errors.image && (
                    <span className="text-danger">{errors.image.message}</span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="text-danger">Name is required</span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    Father's Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Father's Name"
                    {...register("fatherName", {
                      required: true,
                    })}
                  />
                  {errors.fatherName && (
                    <span className="text-danger">Father Name is required</span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    Date Of Birth <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="E-mail"
                    {...register("dateOfBirth", { required: true })}
                  />
                  {errors.dateOfBirth && (
                    <span className="text-danger">
                      Date Of Birth is required
                    </span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    Phone <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    {...register("phone", { required: true })}
                    maxLength={10}
                  />
                  {errors.phone && (
                    <span className="text-danger">Phone is required</span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    State <span className="text-danger">*</span>
                  </label>
                  <select
                    className={"form-control"}
                    {...register("state", { required: true })}
                    onChange={handleStateChange}
                  >
                    <option value="" disabled>
                      Select State
                    </option>
                    {stateList?.map((val: any, index: number) => (
                      <option key={index} value={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <span className="text-danger">State is required</span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    District <span className="text-danger">*</span>
                  </label>
                  <select
                    className={"form-control"}
                    {...register("district", { required: true })}
                  >
                    <option value="" disabled>
                      Select District
                    </option>
                    {districtList?.map((val: any, index: number) => (
                      <option key={index} value={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  {errors.district && (
                    <span className="text-danger">District is required</span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    Pincode <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Pincode"
                    {...register("pinCode", { required: true })}
                  />
                  {errors.pinCode && (
                    <span className="text-danger">Pincode is required</span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Correspondence Address{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control no-resize"
                    placeholder="Address"
                    {...register("correspondenceAddress", { required: true })}
                  ></textarea>
                  {errors.correspondenceAddress && (
                    <span className="text-danger">
                      Correspondence Address is required
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Permanent Address <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control no-resize"
                    placeholder="Address"
                    {...register("permanentAddress", { required: true })}
                  ></textarea>
                  {errors.permanentAddress && (
                    <span className="text-danger">
                      Permanent Address is required
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="E-mail"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className="text-danger">Email is required</span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Password"
                    {...register("password", { required: true })}
                    maxLength={10}
                  />
                  {errors.password && (
                    <span className="text-danger">Password is required</span>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="row clearfix">
              <div className={"col-lg-12"}>
                <div className="row clearfix">
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <label>
                        Employee ID <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Employee ID"
                        {...register("employeeID", { required: true })}
                      />
                      {errors.employeeID && (
                        <span className="text-danger">
                          Employee Id is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <label>
                        Department <span className="text-danger">*</span>
                      </label>
                      <select
                        className={"form-control"}
                        {...register("department", { required: true })}
                      >
                        <option value="" disabled>
                          Select Department
                        </option>
                      </select>
                      {errors.department && (
                        <span className="text-danger">
                          Department is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <label>
                        Designation <span className="text-danger">*</span>
                      </label>
                      <select
                        className={"form-control"}
                        {...register("designation", { required: true })}
                      >
                        <option value="" disabled>
                          Select Designation
                        </option>
                      </select>
                      {errors.designation && (
                        <span className="text-danger">
                          Designation is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <label>
                        Date Of Joining <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Date Of Joining"
                        {...register("dateOfJoining", { required: true })}
                      />
                      {errors.dateOfJoining && (
                        <span className="text-danger">
                          Date Of Joining is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <label>
                        Joining Salary <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Joining Salary"
                        {...register("joiningSalary", { required: true })}
                      />
                      {errors.joiningSalary && (
                        <span className="text-danger">
                          Joining Salary is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    Account Holder Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Account Holder Name"
                    {...register("accountHolderName", { required: true })}
                  />
                  {errors.accountHolderName && (
                    <span className="text-danger">
                      Account Holder Name is required
                    </span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    Account Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Account Number"
                    {...register("accountNumber", { required: true })}
                  />
                  {errors.accountNumber && (
                    <span className="text-danger">
                      Account Number is required
                    </span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    Bank Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Bank Name"
                    {...register("bankName", { required: true })}
                  />
                  {errors.bankName && (
                    <span className="text-danger">Bank Name is required</span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    IFSC Code <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="IFSC Code"
                    {...register("ifscCode", { required: true })}
                  />
                  {errors.ifscCode && (
                    <span className="text-danger">IFSC Code is required</span>
                  )}
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <label>
                    Branch <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Branch"
                    {...register("branch", { required: true })}
                  />
                  {errors.branch && (
                    <span className="text-danger">Branch is required</span>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <WebLayout pageName="CreateEmployee">
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-12">
                <ul className="breadcrumb breadcrumb-style ">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">
                      Create ASL Account (Employee)
                    </h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create ASL Account (Employee)
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="profile-tab-box">
                  <div className="p-l-20">
                    <ul className="nav">
                      <li
                        className={`nav-item tab-all ${
                          currentStep >= 1 ? "active-step" : ""
                        }`}
                        style={{ width: "25%", textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className="nav-link"
                          disabled={currentStep < 1} // Disable tab switching
                        >
                          1. PERSONAL DETAILS
                        </button>
                      </li>
                      <li
                        className={`nav-item tab-all ${
                          currentStep >= 2 ? "active-step" : ""
                        }`}
                        style={{ width: "25%", textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className="nav-link"
                          disabled={currentStep < 2} // Disable tab switching
                        >
                          2. ACCOUNT LOGIN
                        </button>
                      </li>
                      <li
                        className={`nav-item tab-all ${
                          currentStep >= 3 ? "active-step" : ""
                        }`}
                        style={{ width: "25%", textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className="nav-link"
                          disabled={currentStep < 3} // Disable tab switching
                        >
                          3. COMPANY DETAILS
                        </button>
                      </li>
                      <li
                        className={`nav-item tab-all ${
                          currentStep >= 4 ? "active-step" : ""
                        }`}
                        style={{ width: "25%", textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className="nav-link"
                          disabled={currentStep < 4} // Disable tab switching
                        >
                          4. BANK ACCOUNT DETAILS
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="card project_widget">
                <div className="header">
                  <h2>
                    {currentStep === 1 && "PERSONAL DETAILS"}
                    {currentStep === 2 && "ACCOUNT LOGIN"}
                    {currentStep === 3 && "COMPANY DETAILS"}
                    {currentStep === 4 && "BANK ACCOUNT DETAILS"}
                  </h2>
                </div>
                <div className="body">
                  <FormProvider {...methods}>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      encType="multipart/form-data"
                    >
                      {renderStep()}

                      <div className="col-md-12 d-flex justify-content-between mt-3">
                        {currentStep > 1 && (
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={prevStep}
                            disabled={btnLoading}
                          >
                            Previous
                          </button>
                        )}
                        {currentStep < 4 && (
                          <button
                            type="button"
                            className="btn btn-primary ml-auto"
                            onClick={nextStep}
                            disabled={btnLoading}
                          >
                            Next
                          </button>
                        )}
                        {currentStep === 4 && (
                          <button
                            type="submit"
                            className="btn btn-success ml-auto"
                            disabled={btnLoading}
                          >
                            {btnLoading ? (
                              <i className="fa fa-spinner fa-spin"></i>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        )}
                      </div>
                    </form>
                  </FormProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default CreateEmployee;
