import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { BASE_URL, EMPLOYER_TOKEN } from "../../utils/Constants";
import toast from "react-hot-toast";
import DepartmentType, {
  CreateDepartment,
  GetDepartment,
} from "../type/departmentType";
import LogoutAction from "./LogoutAction";

interface Action {
  type: string;
  payload?: any;
}

const DepartmentAction = {
  getDepartmentList:
    (data: GetDepartment): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "department/list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
        );

        dispatch({
          type: DepartmentType.GET_DEPARTMENT_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  createDepartment:
    (data: CreateDepartment): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "department/create",
          {
            name: data.name,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );
        dispatch({
          type: DepartmentType.CREATE_DEPARTMENT,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        console.log(error.message);
      }
    },
};

export default DepartmentAction;
