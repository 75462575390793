import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";

function Home() {
  function menuCheck() {
    return false;
  }

  return (
    <WebLayout pageName={"Home"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style ">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Dashboard</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <a href="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-xl-3 col-sm-6">
              <div className="card l-bg-purple">
                <div className="info-box-5 p-4">
                  <div className="card-icon card-icon-large">
                    <i className="far fa-window-restore"></i>
                  </div>
                  <div className="mb-4">
                    <h5 className="font-20 mb-0">Total Members</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">00</h2>
                    </div>
                    <div className="col-4 text-end">
                      <span className="fw-bold">
                        0.0% <i className="fa fa-arrow-up"></i>
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 head_1" data-height="8">
                    <div
                      className="progress-bar l-bg-cyan head_2"
                      data-width="25%"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-blue-dark">
                <div className="info-box-5 p-4">
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-users"></i>
                  </div>
                  <div className="mb-4">
                    <h5 className="font-20 mb-0">Total Employer</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">00</h2>
                    </div>
                    <div className="col-4 text-end">
                      <span>
                        0.0% <i className="fa fa-arrow-up"></i>
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 head_1" data-height="8">
                    <div
                      className="progress-bar l-bg-green head_2"
                      data-width="25%"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-green-dark">
                <div className="info-box-5 p-4">
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-tasks"></i>
                  </div>
                  <div className="mb-4">
                    <h5 className="font-20 mb-0">Total Registration</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">00</h2>
                    </div>
                    <div className="col-4 text-end">
                      <span>
                        0.0% <i className="fa fa-arrow-up"></i>
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 head_1" data-height="8">
                    <div
                      className="progress-bar l-bg-orange head_2"
                      data-width="25%"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-orange-dark">
                <div className="info-box-5 p-4">
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-money-check-alt"></i>
                  </div>
                  <div className="mb-4">
                    <h5 className="font-20 mb-0">New Joining</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">00</h2>
                    </div>
                    <div className="col-4 text-end">
                      <span>
                        0.0% <i className="fa fa-arrow-up"></i>
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 head_1" data-height="8">
                    <div
                      className="progress-bar l-bg-cyan head_2"
                      data-width="25%"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-sky-dark">
                <div className="info-box-5 p-4">
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-money-check-alt"></i>
                  </div>
                  <div className="mb-4">
                    <h5 className="font-20 mb-0">Total Billing</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">00</h2>
                    </div>
                    <div className="col-4 text-end">
                      <span>
                        0.0% <i className="fa fa-arrow-up"></i>
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 head_1" data-height="8">
                    <div
                      className="progress-bar l-bg-cyan head_2"
                      data-width="25%"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-red-dark">
                <div className="info-box-5 p-4">
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-money-check-alt"></i>
                  </div>
                  <div className="mb-4">
                    <h5 className="font-20 mb-0">Month Joining</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">00</h2>
                    </div>
                    <div className="col-4 text-end">
                      <span>
                        0.0% <i className="fa fa-arrow-up"></i>
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 head_1" data-height="8">
                    <div
                      className="progress-bar l-bg-cyan head_2"
                      data-width="25%"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-pink-dark">
                <div className="info-box-5 p-4">
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-money-check-alt"></i>
                  </div>
                  <div className="mb-4">
                    <h5 className="font-20 mb-0">Total Income</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">00</h2>
                    </div>
                    <div className="col-4 text-end">
                      <span>
                        0.0% <i className="fa fa-arrow-up"></i>
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 head_1" data-height="8">
                    <div
                      className="progress-bar l-bg-cyan head_2"
                      data-width="25%"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-6">
              <div className="card l-bg-white-dark">
                <div className="info-box-5 p-4">
                  <div className="card-icon card-icon-large">
                    <i className="fas fa-money-check-alt"></i>
                  </div>
                  <div className="mb-4">
                    <h5 className="font-20 mb-0">Total Pension</h5>
                  </div>
                  <div className="row align-items-center mb-2 d-flex">
                    <div className="col-8">
                      <h2 className="d-flex align-items-center mb-0">00</h2>
                    </div>
                    <div className="col-4 text-end">
                      <span>
                        0.0% <i className="fa fa-arrow-up"></i>
                      </span>
                    </div>
                  </div>
                  <div className="progress mt-1 head_1" data-height="8">
                    <div
                      className="progress-bar l-bg-cyan head_2"
                      data-width="25%"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}
export default Home;
