const WebType = {
    GET_STATE_LIST: "GET_STATE_LIST",
    GET_DISTRICT_LIST: "GET_DISTRICT_LIST",
    GET_COMPANY_TYPE_LIST: "GET_COMPANY_TYPE_LIST",
};


export interface GetDistrict {
    state_id: number;
}

export default WebType;
