import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import EmployeeAction from "../../stores/action/employeeAction";

function EmployeeList() {
  const [search, set_search] = useState<any>("");
  const [limit, set_limit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);
  const [type, setType] = useState<any>();
  const dispatch = useDispatch<any>();

  const {
    employeeList,
    totalApprovedKYC,
    totalPendingKYC,
    currentPage,
    lastPage,
    totalData,
    from,
    to,
  } = useSelector((state: RootState) => state.employee);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getEmployerList = useCallback(async () => {
    await dispatch(
      EmployeeAction.getEmployeeList({
        limit: limit,
        search: search,
        page: page,
        type: type,
      }),
    );
  }, [dispatch, limit, search, page, type]);

  useEffect(() => {
    getEmployerList();
  }, [getEmployerList, search, limit, page, type]);

  return (
    <WebLayout pageName={"EmployerList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style ">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Employee List</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">Employee List</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header d-flex justify-content-between">
                  <div>
                    <button
                      className="btn btn-success"
                      onClick={() => setType("VERIFIED")}
                    >
                      KYC Approved List{" "}
                      <span className="badge" style={{ top: 0 }}>
                        {totalApprovedKYC}
                      </span>
                    </button>{" "}
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-danger"
                      onClick={() => setType("NOT VERIFIED")}
                    >
                      KYC Pending List{" "}
                      <span className="badge" style={{ top: 0 }}>
                        {totalPendingKYC}
                      </span>
                    </button>
                  </div>
                  <div>
                    <Link to={"../create-employee"}>
                      <button className="btn btn-success">
                        Create ASL Account (Employee)
                      </button>
                    </Link>
                  </div>
                </div>
                <div
                  className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end"
                >
                  <div className="col-md-3">
                    <label className="mb-2">Show Entries</label>
                    <select
                      onChange={(e) => set_limit(e.target.value)}
                      id="defaultSelect"
                      className="form-select"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-3">
                    <div className="input-group input-group-merge mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => set_search(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center"> Employee Id</th>
                          <th className="center"> Image</th>
                          <th className="center"> Name</th>
                          <th className="center"> Communication</th>
                          <th className="center"> Address</th>
                          <th className="center"> Dept./Designation</th>
                          <th className="center"> Joining Date</th>
                          <th className="center"> KYC Status</th>
                          <th className="center"> Account Status</th>
                          <th className="center"> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeList?.length > 0 ? (
                          employeeList.map((dataList: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {dataList.emp_no ? dataList.emp_no : "N/A"}
                                </td>
                                <td>
                                  <img
                                    src={dataList.profile_img}
                                    alt="Profile Image"
                                    width={80}
                                    height={80}
                                  />
                                </td>
                                <td>{dataList.name}</td>
                                <td>
                                  <span style={{ fontWeight: "bold" }}>
                                    Mobile :{" "}
                                  </span>{" "}
                                  {dataList.mobile}
                                  <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Email :{" "}
                                  </span>{" "}
                                  {dataList.email}
                                </td>
                                <td>
                                  <span style={{ fontWeight: "bold" }}>
                                    Address :{" "}
                                  </span>{" "}
                                  {dataList.present_address}
                                  <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    State :{" "}
                                  </span>{" "}
                                  {dataList.sName}
                                  <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    District :{" "}
                                  </span>{" "}
                                  {dataList.dName} <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    Pincode :{" "}
                                  </span>{" "}
                                  {dataList.pincode}
                                </td>
                                <td>
                                  <span style={{ fontWeight: "bold" }}>
                                    Department :{" "}
                                  </span>{" "}
                                  {dataList.department
                                    ? dataList.department
                                    : "N/A"}
                                  <br />
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Designation :{" "}
                                  </span>{" "}
                                  {dataList.designation
                                    ? dataList.designation
                                    : "N/A"}
                                </td>
                                <td>{dataList.doj ? dataList.doj : "N/A"}</td>
                                <td>
                                  {dataList?.kyc_status === "VERIFIED" ? (
                                    <a className="btn btn-success btn-sm">
                                      {dataList.kyc_status}
                                    </a>
                                  ) : dataList?.kyc_status ===
                                    "SEND FOR APPROVAL" ? (
                                    <a className="btn btn-warning btn-sm">
                                      {dataList.kyc_status}
                                    </a>
                                  ) : dataList?.kyc_status ===
                                    "NOT VERIFIED" ? (
                                    <a className="btn bg-purple btn-sm">
                                      {dataList.kyc_status}
                                    </a>
                                  ) : dataList?.kyc_status === "REJECTED" ? (
                                    <a className="btn btn-danger btn-sm">
                                      {dataList.kyc_status}
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  <button
                                    className={`btn btn-sm ${dataList.employee_status === "PENDING" ? "btn-warning" : dataList.employee_status === "ACTIVE" ? "btn-info" : "btn-danger"}`}
                                    style={{ borderRadius: "0 !important" }}
                                  >
                                    {dataList.employee_status}
                                  </button>
                                </td>
                                <td>
                                  <Link
                                    to={`../employee-profile/${dataList.id}`}
                                  >
                                    <button
                                      className={"btn btn-sm btn-success"}
                                      style={{ borderRadius: "0 !important" }}
                                    >
                                      View
                                    </button>
                                  </Link>
                                  &nbsp;
                                  <button
                                    className={"btn btn-sm btn-danger"}
                                    style={{ borderRadius: "0 !important" }}
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10}>No matching records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {employeeList?.length > 0 ? (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto me-auto ">
                        <div className="dt-info">
                          Showing {from} to {to} of {totalData} entries
                        </div>
                      </div>
                      <div className="col-md-auto ms-auto ">
                        <div className="dt-paging paging_full_numbers">
                          <ul className="pagination">
                            <li
                              className="dt-paging-button page-item disabled"
                              onClick={() => set_page(1)}
                            >
                              <a
                                className="page-link first"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="First"
                                data-dt-idx="first"
                              >
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li
                              className={`dt-paging-button page-item prev ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page === 1 ? "" : set_page(currentPage - 1)
                              }
                            >
                              <a
                                className="page-link previous"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="Previous"
                                data-dt-idx="previous"
                              >
                                <span aria-hidden="true">&lt;</span>
                              </a>
                            </li>
                            {pagination.map((p: any, index: number) => {
                              return (
                                <li
                                  key={index}
                                  className={`dt-paging-button page-item ${
                                    currentPage === p ? "active" : ""
                                  }`}
                                  onClick={() => set_page(p)}
                                >
                                  <a
                                    className="page-link"
                                    aria-controls="tableGroup"
                                    aria-current="page"
                                    data-dt-idx="0"
                                  >
                                    {p}
                                  </a>
                                </li>
                              );
                            })}

                            <li
                              className={`dt-paging-button page-item next ${
                                lastPage === page ? "disabled" : ""
                              }`}
                              onClick={() =>
                                lastPage === page
                                  ? ""
                                  : set_page(currentPage + 1)
                              }
                            >
                              <a
                                className="page-link next"
                                aria-controls="tableGroup"
                                aria-label="Next"
                                data-dt-idx="next"
                              >
                                <span aria-hidden="true">&gt;</span>
                              </a>
                            </li>
                            <li
                              className="dt-paging-button page-item"
                              onClick={() => set_page(lastPage)}
                            >
                              <a
                                className="page-link last"
                                aria-controls="tableGroup"
                                aria-label="Last"
                                data-dt-idx="last"
                              >
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EmployeeList;
