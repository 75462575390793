import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../stores";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {EMPLOYER_TOKEN} from "../../utils/Constants";
import AuthAction from "../../stores/action/authAction";
import toast from "react-hot-toast";

type FormData = {
    username: any;
    password: any;
};

function Login() {
    const {register, handleSubmit, formState} = useForm<FormData>();
    const dispatch = useDispatch()<any>;
    const {authenticated, token} = useSelector(
        (state: RootState) => state.auth
    );
    const page = useNavigate();

    useEffect(() => {
        const tokens = localStorage.getItem(EMPLOYER_TOKEN);
        if ((authenticated && token) || tokens) {
            page("/dashboard");
            toast.success("Welcome to ASL Employer Panel !!");
        }
    }, [authenticated, page, token]);

    const {isSubmitting} = formState;
    const onSubmit = handleSubmit(async (data) => {
        await dispatch(
            AuthAction.logIn({
                username: data.username,
                password: data.password,
            })
        );
    });

    return (
        <>
            <div className="login">
                <link href="./assets/css/style.css" rel="stylesheet"/>
                <link href="./assets/css/pages/extra_pages.css" rel="stylesheet"/>

                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100">
                            <form
                                className="login100-form validate-form text-center"
                                onSubmit={onSubmit}>
                                <img
                                    className="p-b-20"
                                    src="./assets/images/asl_logo.png"
                                    width={300}/>
                                <div className="wrap-input100 validate-input">
                                    <input
                                        className="input100"
                                        type="text"
                                        {...register("username")}
                                        placeholder="Username"
                                        required={true}
                                    />
                                </div>
                                <div className="wrap-input100 validate-input">
                                    <input
                                        className="input100"
                                        type="password"
                                        {...register("password")}
                                        placeholder="Password"
                                        required={true}
                                    />
                                </div>

                                <div className="container-login100-form-btn">
                                    <button type="submit" disabled={isSubmitting} className="button-50"
                                            role="button">    {isSubmitting && (
                                        <span className="fa fa-spinner fa-spin mr-1"></span>
                                    )}&nbsp;LOGIN
                                    </button>
                                </div>
                            </form>
                            <div className="login100-more"
                                 style={{backgroundImage: 'url("./assets/images/login_bg.png")'}}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
