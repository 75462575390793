import WebType from "../type/webType";

interface ReducerAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  stateList: [],
  districtList: [],
  companyTypeList: [],
};

const WebReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case WebType.GET_STATE_LIST:
      return {
        ...state,
        stateList: action.payload.data,
      };

    case WebType.GET_DISTRICT_LIST:
      return {
        ...state,
        districtList: action.payload.data,
      };

    case WebType.GET_COMPANY_TYPE_LIST:
      return {
        ...state,
        companyTypeList: action.payload.data,
      };

    default:
      return state;
  }
};

export default WebReducers;
