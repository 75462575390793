const DepartmentType = {
  CREATE_DEPARTMENT: "CREATE_DEPARTMENT",
  GET_DEPARTMENT_LIST: "GET_DEPARTMENT_LIST",
};

export interface CreateDepartment {
  name: string;
}

export interface GetDepartment {
  search: string;
  limit: number;
  page: number;
}

export default DepartmentType;
