const EmployeeType = {
  CREATE_EMPLOYEE: "CREATE_EMPLOYEE",
  GET_EMPLOYEE_LIST: "GET_EMPLOYEE_LIST",
};

export interface createEmployee {
  companyImage: any;
  name: string;
  no_of_employee: number;
  email: string;
  mobile: any;
  typeOfCompany: any;
  state: number;
  district: number;
  pincode: number;
  address: string;
}

export interface GetEmployee {
  search: string;
  limit: number;
  page: number;
  type: string;
}

export default EmployeeType;
